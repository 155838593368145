import "./main.css";

import React, { useState, useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";

import nftalley from "../assets/imgs/NFT_Alley_Final.png";

import ConnectWallet from "./ConnectWallet";
import getWeb3 from "../web3/getWeb3";

import dots from "../assets/imgs/dots.svg";

import MenuAppBar from "../Nav/AlleyNav";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import metamask from "../assets/imgs/metamask.svg";
import wc from "../assets/imgs/wc.svg";
import config from "../contractConst.json";

export default function ConnectWalletRoot() {
  var WALLET = {
    METAMASK: { value: 0, name: "META MASK" },
    WALLETCONNECT: { value: 1, name: "WALLET CONNECT" },
  };
  //Local State of Web3
  const [web3js, setWeb3] = useState(null);
  // Local State of Wallet Accounts
  const [accounts, setAccounts] = useState(null);

  const [networkId, setNetworkId] = useState(null);

  const [provider, setProvider] = useState(null);

  const [selectedWallet, setSelectedWallet] = useState(null);

  const onboarding = React.useRef();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
      addBinanceChain();
    }
  }, []);

  useEffect(() => {}, [networkId]);

  const walletNotConnected = () => {
    return (
      <div
        style={{
          height: "calc(100% - 64px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: "500", fontSize: "20px" }}>
          Please Connect the wallet{" "}
        </p>
        <div className="wallet-wrapper">
          <div
            onClick={() => {
              MetaMaskOnboarding.isMetaMaskInstalled()
                ? initWallet(0)
                : onboarding.current.startOnboarding();
            }}
            className="wallet-block"
          >
            <img height="50" src={metamask} alt="..." />
            {MetaMaskOnboarding.isMetaMaskInstalled() ? (
              <p>MetaMask</p>
            ) : (
              <p style={{ textDecoration: "underline" }}> Install MetaMask</p>
            )}
          </div>
          <div
            onClick={() => {
              initWallet(1);
            }}
            className="wallet-block"
          >
            <img height="50" src={wc} alt="..." />
            <p>WalletConnect</p>
          </div>
        </div>
      </div>
    );
  };

  const openLink = (a) => {
    window.open(a, "_blank");
  };

  if (provider) {
    provider.on("accountsChanged", function (accounts) {
      if (accounts.length > 0) {
        setAccounts(accounts);
      } else {
        setProvider(null);
        setWeb3(null);
        setAccounts(null);
      }
    });
    provider.on("chainChanged", function (networkId) {
      if (typeof networkId === "string") {
        setNetworkId(parseInt(networkId, 16));
      } else {
        setNetworkId(networkId);
      }
    });

    provider.on("disconnect", (code, reason) => {
      if (selectedWallet === 1) {
        setProvider(null);
        setWeb3(null);
        setAccounts(null);
      }
    });
  }

  const disconnectWallet = async () => {
    
    if (selectedWallet === 1) {
      await provider.disconnect();
    }
  };

  const addBinanceChain = () => {
    if (provider) {
      provider
        .request({
          method: "wallet_addEthereumChain",
          params: [config.main_net], /// Change to test_net to support testnet
        })
        .then((res) => {
          initWallet(0);
        })
        .catch((err) => {});
    }
  };

  const addAlleyToken = () => {
    if (provider) {
      web3js.currentProvider.sendAsync({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: config.contract_address[0].AlleyToken.address,
            symbol: "ALLEY",
            decimals: 18,
            image:
              "https://gateway.pinata.cloud/ipfs/QmZE3WrVBJCo45ca2L3Sey5Bb5LzxxctVzwmBwCcQsJrsL",
          },
        },
        id: 1 * 10000,
      });
    }
  };

  const initWallet = async (provider_nos) => {
    const web3js = await getWeb3(provider_nos);
    // if (MetaMaskOnboarding.isMetaMaskInstalled()) {
    try {
      // Get network provider and web3 instance.

      // web3js.currentProvider.setMaxListeners(300);

      setProvider(web3js.currentProvider);

      setSelectedWallet(provider_nos);

      // Use web3 to get user's accounts
      const accounts = await web3js.eth.getAccounts();

      // get chain id
      const networkId = await web3js.eth.net.getId();

      // Set Local state of Accounts
      setAccounts(accounts);

      setNetworkId(networkId);

      // Set Local state of  Web3
      setWeb3(web3js);
    } catch (e) {
      console.error(e);
    }
    //}
  };

  const rightPane = () => {
    return (
      <div className="right-panel">
        <MenuAppBar
          web3={web3js}
          connectWeb3={initWallet}
          account={accounts}
          disconnect={disconnectWallet}
          provider_nos={selectedWallet}
          addBinanceChain={addBinanceChain}
        ></MenuAppBar>
        {web3js ? (
          <ConnectWallet
            web3={web3js}
            networkId={networkId}
            accounts={accounts}
            addToken={addAlleyToken}
            provider_nos={selectedWallet}
            disconnect={disconnectWallet}
            addBinanceChain={addBinanceChain}
          />
        ) : (
          walletNotConnected()
        )}
      </div>
    );
  };

  const leftPane = () => {
    return (
      <div className="left-panel">
        {/* <div className="pink-bubble"></div>
        <div className="yellow-bubble"></div>
        <div className="purple-bubble"></div>
        <div className="red-bubble"></div> */}
        <div className="glass"></div>

        <span style={{ zIndex: "3" }}>
          <img className="photo1" src={nftalley} alt="a" />{" "}
        </span>
        <div className="social" style={{ zIndex: "3" }}>
          <div
            className="ico-nft twitter"
            onClick={() => openLink("https://twitter.com/nft_alley?s=09")}
          >
            <TwitterIcon />
          </div>
          <div
            className="ico-nft facebook"
            onClick={() => openLink("https://facebook.com/NFTAlley")}
          >
            <FacebookIcon />
          </div>
          <div
            className="ico-nft instagram"
            onClick={() => openLink("https://instagram.com/nft_alley/")}
          >
            <InstagramIcon />
          </div>
          <div
            className="ico-nft telegram"
            onClick={() => openLink("https://t.me/nftalley")}
          >
            <TelegramIcon />
          </div>
        </div>
      </div>
    );
  };

  const noWalletDetected = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img height="100" src={metamask} alt="..." />
        <p>
          We Support MetaMask Wallet.Seems Like metamask is missing from your
          browser.
        </p>
        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "green" }}
          startIcon={<AddIcon />}
          onClick={() => {
            onboarding.current.startOnboarding();
          }}
        >
          Install Meta Mask
        </Button>
      </div>
    );
  };

  return (
    <div>
      <img
        src={dots}
        alt="..."
        style={{ position: "absolute", bottom: "0", left: "0" }}
      />
      <div className="container-div">
        <div className="upper-strip"></div>
        <div className="main">
          {leftPane()}
          {rightPane()}
        </div>
      </div>
    </div>
  );
}
