import "./main.css";

import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import ABIAlleyBEP20 from "../ABI/AlleyToken.json";
import ABIEarlyBackers from "../ABI/EarlyBackers.json";
import VestingABI from "../ABI/Vesting.json";
import config from "../contractConst.json";

import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Vesting(props) {
  const { web3, networkId, accounts, addToken } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  // Local State of AlleyToken
  const [alleyBEP20, setAlleyBEP20] = useState(null);
  const [earlybackers, setEarlyBackers] = useState(null);
  const [team, setTeam] = useState(null);
  const [reward, setReward] = useState(null);
  const [advisor, setAdvisor] = useState(null);
  const [foundation, setFoundation] = useState(null);
  const [alleyBalance, setAlleyBalance] = useState(null);
  const [totalAlleyBalanceTeam, setTotalAlleyBalanceTeam] = useState(null);
  const [totalAlleyBalanceAdvisor, setTotalAlleyBalanceAdvisor] = useState(
    null
  );
  const [totalAlleyBalanceReward, setTotalAlleyBalanceReward] = useState(null);
  const [
    totalAlleyBalanceFoundation,
    setTotalAlleyBalanceFoundation,
  ] = useState(null);
  const [isLoadingTeam, setisLoadingTeam] = useState(false);
  const [isLoadingAdvisor, setisLoadingAdvisor] = useState(false);
  const [isLoadingFoundation, setisLoadingFoundation] = useState(false);
  const [isLoadingReward, setisLoadingReward] = useState(false);
  const [isLoadingInvestedAlley, setisLoadingInvestedAlley] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [msg, setMsg] = useState(false);
  const [rBal, setReleaseBal] = useState(null);
  const [aHash, setAHash] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [txResult, setTxResult] = useState(null);
  const [txMsg, setTxMsg] = useState(null);

  /**
   *
   */

  const initContracts = () => {
    

    const AlleyBEP20Contract = new web3.eth.Contract(
      ABIAlleyBEP20.abi,
      config.contract_address[0]["AlleyToken"].address
    );
    const EarlyBackersContract = new web3.eth.Contract(
      ABIEarlyBackers.abi,
      config.contract_address[0]["EarlyBackers"].address
    );
    const TeamABIContract = new web3.eth.Contract(
      VestingABI.abi,
      config.contract_address[0]["TeamVesting"].address
    );
    const AdvisorABIContract = new web3.eth.Contract(
      VestingABI.abi,
      config.contract_address[0]["AdvisorVesting"].address
    );
    const RewardABIContract = new web3.eth.Contract(
      VestingABI.abi,
      config.contract_address[0]["Reward"].address
    );
    const FoundationABIContract = new web3.eth.Contract(
      VestingABI.abi,
      config.contract_address[0]["Foundation"].address
    );
    setAlleyBEP20(AlleyBEP20Contract);
    setEarlyBackers(EarlyBackersContract);
    setTeam(TeamABIContract);
    setAdvisor(AdvisorABIContract);
    setReward(RewardABIContract);
    setFoundation(FoundationABIContract);
  };

  /**
   *
   */
  const getAlleyTokens = async (contract,i) => {
    
    if (contract) {
      var alleyBalance = await contract.methods.releasableAmount().call();
      setAlleyBal(i,alleyBalance)
      
    }
  };

  /**
   *
   */
  const getInvestedAlleyTokens = async (address) => {
   
    if (alleyBEP20) {
      var alleyBalance = await alleyBEP20.methods.balanceOf().call();
      //   setTotalAlleyBalance(
      //     web3.utils.fromWei(alleyBalance.toString(), "ether")
      //   );
      setisLoadingInvestedAlley(false);
    }
  };

  /**
   *
   */

  const getRAlleyTokens = async () => {
    
    if (earlybackers) {
      var relaseBal = await earlybackers.methods
        .releasableAmount(accounts[0])
        .call();
      setReleaseBal(web3.utils.fromWei(relaseBal.toString(), "ether"));
    }
  };

  const setLoader = (i, action) => {
   
    switch (i) {
      case 0:
          
        setisLoadingTeam(action);
        break;
      case 1:
        setisLoadingAdvisor(action);
        break;
      case 2:
        setisLoadingReward(action);
        break;
      case 3:
        setisLoadingFoundation(action);
        break;
    }
  };

  const setAlleyBal = (i, val) => {
    
    switch (i) {
      case 0:
        setTotalAlleyBalanceTeam(web3.utils.fromWei(val.toString(), "ether") + " ALLEY");
        break;
      case 1:
        setTotalAlleyBalanceAdvisor(web3.utils.fromWei(val.toString(), "ether") + " ALLEY");
        break;
      case 2:
        setTotalAlleyBalanceReward(web3.utils.fromWei(val.toString(), "ether") + " ALLEY");
        break;
      case 3:
        setTotalAlleyBalanceFoundation(web3.utils.fromWei(val.toString(), "ether") + " ALLEY");
        break;
    }
  };

  const getLoader = (i) => {
    switch (i) {
      case 0:
        return isLoadingTeam;

      case 1:
        return isLoadingAdvisor;
      case 2:
        return isLoadingReward;
      case 3:
        return isLoadingFoundation;
    }
  };

  const getAlleyBal = (i) => {
     switch (i) {
      case 0:
        return totalAlleyBalanceTeam;

      case 1:
        return totalAlleyBalanceAdvisor;
      case 2:
        return totalAlleyBalanceReward;
      case 3:
        return totalAlleyBalanceFoundation;
    }
  };

  /**
   *
   */

  const releseAlley = async (contract, i) => {
    
    setLoader(i, true);
    setDisableButton(true);
    if (contract) {
      try {
        await contract.methods
          .release()
          .send({
            from: accounts[0],
          })
          .on("receipt", (res) => {
            setLoader(i, false);
            setDisableButton(false);
            getAlleyTokens(contract,i)
            setTxResult("success");
            setTxMsg("Transaction was successful");
            setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
            setAHash(res.transactionHash.toString());
            handleClick({ vertical: "bottom", horizontal: "left" })();
            
          })
          .on('error', (error, res) => {
            console.error(error);
            if (error.code) {
              if (error.code === 4001) {
               
              }
            } else {
              setTxResult("error");
              setTxMsg("Error encountered during contract execution");
              setTxHash(
                res.transactionHash.toString().substring(0, 40) + "..."
              );
              handleClick({ vertical: "bottom", horizontal: "left" })();
              setAHash(res.transactionHash.toString());
            }
          });
      } catch (e) {
        console.error(e);
        setLoader(i, false);
        setDisableButton(false);
      }
    }
  };

  useEffect(() => {
    initContracts();
  }, [accounts]);

  useEffect(() => {
    getAlleyTokens(team,0);
  }, [team]);

  useEffect(() => {
    getAlleyTokens(reward,2);
  }, [reward]);

  useEffect(() => {
    getAlleyTokens(advisor,1);
  }, [advisor]);

  useEffect(() => {
    getAlleyTokens(foundation,3);
  }, [foundation]);


  useEffect(() => {
    getRAlleyTokens();
  }, [earlybackers]);

  const WalleDetails = (title, contract, i, address) => {
    //getInvestedAlleyTokens(address);
    return (
      <>
        <div className="wallet-info" style={{ flex: "1" }}>
          <div className="wallet-info-title">
            <p
              style={{
                borderBottom: "1px solid #f2f2f2",
                margin: "0",
                padding: "10px 10px",
              }}
            >
              {title}
            </p>
            <div className="brand-image">
              <div style={{ margin: "20px 0px 0 0" }}>
                <p
                  style={{
                    padding: "0px 20px",
                    margin: "0",
                    fontWeight: "600",
                  }}
                >
                  Releaseable Amount
                </p>

                <p
                  style={{
                    padding: "0px 20px",
                    margin: "0",
                    color: "rgb(64 64 64)",
                  }}
                >
                  {getAlleyBal(i)}
                </p>

                <p>{msg}</p>
              </div>
              <Button
                variant="contained"
                disabled={disableButton}
                onClick={() => {
                  releseAlley(contract, i);
                }}
                size="medium"
                style={{
                  marginLeft: "auto",
                  background: "#e80368",
                  color: "#FFF",
                 
                  textTransform: "capitalize",
                }}
              >
                {" "}
                {getLoader(i) ? (
                  <CircularProgress
                    size={25.0}
                    style={{ padding: "10px", margin: "0" }}
                  />
                ) : (
                  " Release ALLEY"
                )}
              </Button>
            </div>

            <div style={{ margin: "20px 0px" }}>
              <p
                style={{ padding: "0px 20px", margin: "0", fontWeight: "600" }}
              >
                Contract Address
              </p>
              <a
                href={config.chain_url + "address/" + address}
                target="_blank"
                style={{
                  padding: "20px 20px",
                  margin: "0",
                  color: "rgb(64 64 64)",
                  marginBottom: "50px",
                  paddingBottom: "10px",
                }}
              >
                {address ? (
                  address.substr(0, 27) +
                  "..." +
                  address.substr(address.toString().length - 5, address.length)
                ) : (
                  <CircularProgress
                    size={20.0}
                    style={{ padding: "10px", margin: "0" }}
                  />
                )}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  const action = (
    <Button color="secondary" size="small">
      View on Chain
    </Button>
  );

  const alertSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={txResult}>
          {txMsg} <br />{" "}
          <a
            target="_blank"
            href={config.chain_url + "tx/" + aHash}
            style={{ color: "#fff" }}
          >
            {txHash}
          </a>
        </Alert>
      </Snackbar>
    );
  };

  return (
    <div>
      {alertSnackbar()}
      <div className="connect-wallet">
        <div className={classes.root}>
          <div style={{ display: "flex" }}>
            {" "}
            {team
              ? WalleDetails(
                  "Team Vesting",
                  team,
                  0,
                  config.contract_address[0]["TeamVesting"].address
                )
              : ""}{" "}
            {advisor
              ? WalleDetails(
                  "Advisor Vesting",
                  advisor,
                  1,
                  config.contract_address[0]["AdvisorVesting"].address
                )
              : ""}{" "}
          </div>
          <div style={{ display: "flex" }}>
            {" "}
            {reward
              ? WalleDetails(
                  "EcoSystem And Reward Vesting",
                  reward,
                  2,
                  config.contract_address[0]["Reward"].address
                )
              : ""}{" "}
            {foundation
              ? WalleDetails(
                  "Foundation",
                  foundation,
                  3,
                  config.contract_address[0]["Foundation"].address
                )
              : foundation}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
