import "./main.css";

import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import ABIAlleyBEP20 from "../ABI/AlleyToken.json";
import ABIEarlyBackers from "../ABI/EarlyBackers.json";
import config from "../contractConst.json";
import binance from "../assets/imgs/binance.png";


import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Staking from "./Staking";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ConnectWallet(props) {
  const {
    web3,
    networkId,
    accounts,
    addToken,
    provider_nos,
    disconnect,
    addBinanceChain
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  // Local State of AlleyToken
  const [alleyBEP20, setAlleyBEP20] = useState(null);
  const [earlybackers, setEarlyBackers] = useState(null);
  const [alleyBalance, setAlleyBalance] = useState(null);
  const [totalAlleyBalance, setTotalAlleyBalance] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingInvestedAlley, setisLoadingInvestedAlley] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [rBal, setReleaseBal] = useState(null);
  const [aHash, setAHash] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [txResult, setTxResult] = useState(null);
  const [txMsg, setTxMsg] = useState(null);

  /**
   *
   */
  const getAlleyTokens = async () => {
    if (alleyBEP20) {
      var alleyBalance = await alleyBEP20.methods.balanceOf(accounts[0]).call();

      setAlleyBalance(
        web3.utils.fromWei(alleyBalance.toString(), "ether") + " ALLEY"
      );
    }
  };

  /**
   *
   */
  const getInvestedAlleyTokens = async () => {
    if (earlybackers) {
      var alleyBalance = await earlybackers.methods
        .getBenificiaryTotalAmount(accounts[0])
        .call();
      setTotalAlleyBalance(
        web3.utils.fromWei(alleyBalance.toString(), "ether")
      );
      setisLoadingInvestedAlley(false);
    }
  };

  /**
   *
   */

  const getRAlleyTokens = async () => {
    if (earlybackers) {
      var relaseBal = await earlybackers.methods
        .releasableAmount(accounts[0])
        .call();
      setReleaseBal(web3.utils.fromWei(relaseBal.toString(), "ether"));
    }
  };

  /**
   *
   */

  const releseAlley = async () => {
    setisLoading(true);
    setDisableButton(true);
    if (earlybackers) {
      try {
        await earlybackers.methods
          .release(accounts[0])
          .send({
            from: accounts[0],
          })
          .on("transactionHash", (hash) => {})
          .on("confirmation", function (confirmationNumber, receipt) {})
          .on("receipt", (res) => {
            setisLoading(false);
            setDisableButton(false);
            getAlleyTokens();
            getRAlleyTokens();
            setTxResult("success");
            setTxMsg("Transaction was successful");
            setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
            setAHash(res.transactionHash.toString());
            handleClick({ vertical: "bottom", horizontal: "left" })();
          })
          .on("error", (error, res) => {
            if (res) {
              setTxResult("error");
              setTxMsg("Error encountered during contract execution");
              setTxHash(
                res.transactionHash.toString().substring(0, 40) + "..."
              );
              handleClick({ vertical: "bottom", horizontal: "left" })();
              setAHash(res.transactionHash.toString());
            }
          });
      } catch (e) {
        setisLoading(false);
        setDisableButton(false);
      }
    }
  };

  useEffect(() => {
    /**
     *
     */

    const initContracts = () => {
      const AlleyBEP20Contract = new web3.eth.Contract(
        ABIAlleyBEP20.abi,
        config.contract_address[0]["AlleyToken"].address
      );
      const EarlyBackersContract = new web3.eth.Contract(
        ABIEarlyBackers.abi,
        config.contract_address[0]["EarlyBackers"].address
      );
      setAlleyBEP20(AlleyBEP20Contract);
      setEarlyBackers(EarlyBackersContract);
    };
    initContracts();
  }, [accounts]);

  useEffect(() => {
    getAlleyTokens();
  }, [alleyBEP20]);

  useEffect(() => {
    setisLoading(false);
  }, [alleyBalance]);

  useEffect(() => {
    getInvestedAlleyTokens();
    getRAlleyTokens();
  }, [earlybackers]);

  const AlleyToken = () => {
    return (
      <div className="wallet-info" style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            padding: "20px",
            borderBottom: "1px solid #f4f4f4",
            borderTop: "1px solid #f4f4f4",
          }}
        >
          <img
            src="https://gateway.pinata.cloud/ipfs/QmZE3WrVBJCo45ca2L3Sey5Bb5LzxxctVzwmBwCcQsJrsL"
            alt="..."
            className="brand-img"
          />
          <h1 style={{ fontWeight: "300", margin: "0", marginLeft: "15px" }}>
            Alley Token
          </h1>
          <div
            style={{
              marginLeft: "auto",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "auto", backgroundColor: "green" }}
              startIcon={<AddIcon />}
              onClick={addToken}
              className="add-token-button"
            >
              Add Token to Meta Mask
            </Button>
          </div>
        </div>
        <div style={{ padding: "10px 30px", textAlign: "left" }}>
          <p style={{ fontWeight: "600" }}>
            Name :{" "}
            <span style={{ fontWeight: "400", color: "#4f4f4f" }}>Alley</span>
          </p>
          <p style={{ fontWeight: "600" }}>
            Symbol :{" "}
            <span style={{ fontWeight: "400", color: "#4f4f4f" }}>ALLEY</span>{" "}
          </p>
          <p style={{ fontWeight: "600" }}>
            Decimals :{" "}
            <span style={{ fontWeight: "400", color: "#4f4f4f" }}>18</span>{" "}
          </p>
          <p style={{ fontWeight: "600" }}>
            Address :{" "}
            <a
              target="_blank"
              href={
                config.chain_url +
                "address/" +
                config.contract_address[0]["AlleyToken"].address
              }
              style={{ fontWeight: "400", color: "#4f4f4f" }}
            >
              {config.contract_address[0]["AlleyToken"].address}
            </a>
          </p>
        </div>
      </div>
    );
  };

  const WalleDetails = () => {
    return (
      <>
        <div className="wallet-info">
          <div className="wallet-info-title">
            <p
              style={{
                borderBottom: "1px solid #f2f2f2",
                margin: "0",
                padding: "10px 10px",
              }}
            >
              Wallet Details
            </p>
            <div className="brand-image">
              <div style={{ margin: "20px 0px 0 0" }}>
                <p
                  style={{
                    margin: "0",
                    fontWeight: "600",
                  }}
                >
                  Releaseable Amount
                </p>

                <p
                  style={{
                    margin: "0",
                    color: "rgb(64 64 64)",
                  }}
                >
                  {rBal ? rBal : 0}
                </p>
              </div>
              <Button
                variant="contained"
                disabled={disableButton}
                onClick={releseAlley}
                size="large"
                style={{
                  marginLeft: "auto",
                  background: "#e80368",
                  color: "#FFF",
                  width: "170px",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                {isLoading ? (
                  <CircularProgress
                    size={25.0}
                    style={{ padding: "10px", margin: "0" }}
                  />
                ) : (
                  " Release ALLEY"
                )}
              </Button>
            </div>
            <div
              style={{ margin: "20px 0px", borderBottom: "1px solid #f2f2f2" }}
            >
              <p style={{ margin: "0", fontWeight: "600" }}>
                Total Invested Token
              </p>

              {isLoadingInvestedAlley ? (
                <CircularProgress
                  size={20.0}
                  style={{ padding: "10px", margin: "0" }}
                />
              ) : (
                <p
                  style={{
                    color: "rgb(64 64 64)",
                  }}
                >
                  {totalAlleyBalance.toString() + " ALLEY"}
                </p>
              )}
            </div>
            <div style={{ margin: "20px 0px" }}>
              <p style={{ fontWeight: "600" }}>Contract Address</p>
              <p>
                <a
                  href={
                    config.chain_url +
                    "address/" +
                    config.contract_address[0]["EarlyBackers"].address
                  }
                  target="_blank"
                  style={{
                    color: "rgb(64 64 64)",
                    marginBottom: "50px",
                    paddingBottom: "10px",
                  }}
                >
                  {earlybackers ? (
                    earlybackers.options.address
                  ) : (
                    <CircularProgress
                      size={20.0}
                      style={{ padding: "10px", margin: "0" }}
                    />
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const alertSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={txResult}>
          {txMsg} <br />{" "}
          <a
            target="_blank"
            href={config.chain_url + "tx/" + aHash}
            style={{ color: "#fff" }}
          >
            {txHash}
          </a>
        </Alert>
      </Snackbar>
    );
  };

  const wrongNetwork = () => {
    return (
      <div className="wrong-net">
        <img
          src={binance}
          alt="wrong network"
          height="100"
          width="100"
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
        <p>
          Connected To Wrong Network.Please change network to Binance Smart
          Chain.
        </p>

       {provider_nos === 0?<Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "green" }}
          startIcon={<AddIcon />}
          onClick={addBinanceChain}
          className="add-chain-button"
        >
          Add / Switch BSC Network to Meta Mask 
        </Button>:""} 
      </div>
    );
  };

  return (
    <div>
      {alertSnackbar()}
      <div className="connect-wallet">
        <div className={classes.root}>
          <AppBar position="static">
            
          </AppBar>
         
        
            { config.supported_chains.includes(networkId) ? <Staking web3={web3} networkId={networkId} accounts={accounts} />: wrongNetwork()}
          
          <AppBar className="bottom-app-bar" position="static">
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: "10px", backgroundColor: "red" }}
              onClick={disconnect}
              className="disconnet-button"
            >
              Disconnect Wallet
            </Button>
          </AppBar>
        </div>
      </div>
    </div>
  );
}
