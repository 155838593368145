import "./main.css";

import React, { useState, useEffect } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";
import binance from "../assets/imgs/binance.png";
import { makeStyles } from '@material-ui/core/styles';
import nftalley from "../assets/imgs/NFT_Alley_Final.png";

import ConnectWallet from "./ConnectWallet";
import getWeb3 from "../web3/getWeb3";

import dots from "../assets/imgs/dots.svg";
import wrongNetworkImg from "../assets/imgs/wrong-network.png";
import MenuAppBar from "../Nav/AlleyNav";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";
import PowerIcon from "@material-ui/icons/Power";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import metamask from "../assets/imgs/metamask.svg"
import config from "../contractConst.json";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Vesting from "./Vesting";



const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

export default function TokenRelease() {
  //Local State of Web3
  const [web3js, setWeb3] = useState(null);
  // Local State of Wallet Accounts
  const [accounts, setAccounts] = useState(null);

  const [networkId, setNetworkId] = useState(null);

  const onboarding = React.useRef();
    const classes = useStyles();   
  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
      addBinanceChain();
    }
  }, []);

  useEffect(()=>{
    
  },[networkId])

  const walletNotConnected = () => {
    return (
      <div
        style={{
          height: "calc(100% - 64px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PowerIcon style={{ fontSize: "4.5rem" }} />

        <p>Please connect your wallet</p>
      </div>
    );
  };

  const wrongNetwork = () => {
    return (
      <div
        style={{
          height: "calc(100% - 64px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={binance}
          alt="wrong network"
          height="200"
          width="200"
          
          style={{ borderRadius: "50%",objectFit:"cover" }}
        />
        <p>
          Connected To Wrong Network.Please change network to Binance Smart
          Chain.
        </p>

        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "green" }}
          startIcon={<AddIcon />}
          onClick={addBinanceChain}
         
        >
          Add / Switch BSC Network to Meta Mask
        </Button>
      </div>
    );
  };

  const openLink = (a) => {
    window.open(a, "_blank");
  };

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async function (accounts) {
      setAccounts(accounts);
    });
    window.ethereum.on("chainChanged", async function (networkId) {
      setNetworkId(parseInt(networkId, 16));
    });
  }

  const addBinanceChain = () => {
    if (window.ethereum) {
      
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [config.main_net],
        })
        .then((res) => {
          // initWallet();
        })
        .catch((err) => {
          
        });
    }
  };

  const addAlleyToken = () => {
    if (window.ethereum) {
     
      web3js.currentProvider.sendAsync({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: config.contract_address[0].AlleyToken.address,
            symbol: "ALLEY",
            decimals: 18,
            image:
              "https://gateway.pinata.cloud/ipfs/QmZE3WrVBJCo45ca2L3Sey5Bb5LzxxctVzwmBwCcQsJrsL",
          },
        },
        id: 1 * 10000,
      });
    }
  };

  const initWallet = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      try {
        // Get network provider and web3 instance.

        const web3js = await getWeb3();
        web3js.currentProvider.setMaxListeners(300);

        // Use web3 to get user's accounts
        const accounts = await web3js.eth.getAccounts();

        // get chain id
        const networkId = await web3js.eth.net.getId();

        
        // Set Local state of Accounts
        setAccounts(accounts);

        setNetworkId(networkId);

        // Set Local state of  Web3
        setWeb3(web3js);
       
      } catch (e) {
        console.error(e);
      }
    }
  };

  const rightPane = () => {
    return (
      <div className="right-panel">
        <MenuAppBar
          web3={web3js}
          connectWeb3={initWallet}
          account={accounts}
        ></MenuAppBar>
        {web3js ? (
          config.supported_chains.includes(networkId) ? (
           <Vesting  web3={web3js}
           networkId={networkId}
           accounts={accounts}
           addToken={addAlleyToken}/>
          ) : (
            wrongNetwork()
          )
        ) : (
          walletNotConnected()
        )}
      </div>
    );
  };

 
  const leftPane = () => {
    return (
      <div className="left-panel">
        {/* <div className="pink-bubble"></div>
        <div className="yellow-bubble"></div>
        <div className="purple-bubble"></div>
        <div className="red-bubble"></div> */}
        <div className="glass"></div>

        <span style={{ zIndex: "3" }}>
          <img className="photo1" src={nftalley} alt="a" />{" "}
        </span>
        <div className="social" style={{ zIndex: "3" }}>
          <div
            className="twitter"
            onClick={() => openLink("https://twitter.com/nft_alley?s=09")}
          >
            <TwitterIcon />
          </div>
          <div
            className="facebook"
            onClick={() => openLink("https://facebook.com/NFTAlley")}
          >
            <FacebookIcon />
          </div>
          <div
            className="instagram"
            onClick={() => openLink("https://instagram.com/nft_alley/")}
          >
            <InstagramIcon />
          </div>
          <div
            className="telegram"
            onClick={() => openLink("https://t.me/nftalley")}
          >
            <TelegramIcon />
          </div>
        </div>
      </div>
    );
  };

  const noWalletDetected=()=>{
    return (
      <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%"}}>
        <img height="100" src={metamask} alt="..."/>
        <p>We Support MetaMask Wallet.Seems Like metamask is missing from your browser.</p>
        <Button
          variant="contained"
          color="secondary"
          style={{ backgroundColor: "green" }}
          startIcon={<AddIcon />}
          onClick={()=>{onboarding.current.startOnboarding()}}>Install Meta Mask</Button>
          
      </div>
    )
  }

  return (
    <div>
      <img
        src={dots}
        alt="..."
        style={{ position: "absolute", bottom: "0", left: "0" }}
      />
      <div className="container-div">
        <div className="upper-strip"></div>
        <div className="main">
          {leftPane()}
          {MetaMaskOnboarding.isMetaMaskInstalled() ? rightPane():noWalletDetected()}
        </div>
      </div>
    </div>
  );
}

