import "./main.css";
import { Container } from "reactstrap";
import React from "react";

import nftalley from "../assets/imgs/NFT_Alley_Final.png";
import CheckTokenAllocation from "./CheckTokenAllocation";
import Staking from "./Staking";
import ConnectWallet from "./ConnectWallet";
import dots from "../assets/imgs/dots.svg";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TelegramIcon from "@material-ui/icons/Telegram";

export default function LandingPage() {
  const openLink = (a) => {
    window.open(a, "_blank");
  };

  return (
    <div>
      <img
        src={dots}
        alt="..."
        style={{ position: "absolute", bottom: "0", left: "0" }}
      />
      <Container>
        <div className="container-div">
          <div className="upper-strip"></div>
          <div className="main">
            <div className="left-panel">
              <div className="pink-bubble"></div>
              <div className="yellow-bubble"></div>
              <div className="purple-bubble"></div>
              <div className="red-bubble"></div>
              <div className="glass"></div>

              <span style={{ zIndex: "3" }}>
                <img className="photo1" src={nftalley} alt="a" />{" "}
              </span>
              <div className="social" style={{ zIndex: "3" }}>
                <div
                  className="twitter"
                  onClick={() => openLink("https://twitter.com/nft_alley?s=09")}
                >
                  <TwitterIcon />
                </div>
                <div
                  className="facebook"
                  onClick={() => openLink("https://facebook.com/NFTAlley")}
                >
                  <FacebookIcon />
                </div>
                <div
                  className="instagram"
                  onClick={() => openLink("https://instagram.com/nft_alley/")}
                >
                  <InstagramIcon />
                </div>
                <div
                  className="telegram"
                  onClick={() => openLink("https://t.me/nftalley_ann")}
                >
                  <TelegramIcon />
                </div>
              </div>
            </div>
            <div className="right-panel">
             
              <CheckTokenAllocation />
           
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
