import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import metamask from "../assets/imgs/metamask.svg";
import wc from "../assets/imgs/wc.svg";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    color:"#fff",
    border:"1px solid #fff"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign:"left"
  },
}));

export default function  MenuAppBar(props) {

  const {web3,connectWeb3,account,disconnect,provider_nos} = props;

  const classes = useStyles();

  /**
   * @returns the connected wallet image
   */
  const getWalletImage = () => {
    

    return (<img className="wallet-connected-image" src={provider_nos === 1 ? wc : metamask }  alt="wallet-image" />)
  }
  



 

 
  const truncate = (str, n) => {
    return str.substr(0, n + 1) + "..." + str.substr(str.length - n, str.length);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
         
          <Typography edge="start" variant="h6" className={classes.title}>
            DashBoard
          </Typography>
         
            <div>
           {web3!=null && provider_nos === 1? <Button
              variant="contained"
              color="secondary"
              style={{ margin :"10px", backgroundColor: "red" }}
              
              onClick={disconnect}
              className="disconnet-button-desk"
            >
              Disconnect Wallet
            </Button> :"" } 
              <Button
                  variant="outlined"
                  className={classes.button}
                  endIcon={ web3 ? getWalletImage():<></>}> {web3==null ?"Wallet Not Connected":truncate(account.toString(),5)}</Button>
            </div>
          
        </Toolbar>
      </AppBar>
    </div>
  );
}
