import "./main.css";

import React, { useState, useEffect } from "react";
import config from "../contractConst.json";
import axios from "axios";
import Button from "@material-ui/core/Button";
import AlleyLiquidity from "../ABI/AlleyLiquidity.json";
import LPToken from "../ABI/LPToken.json";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import nftalley from "../assets/imgs/NFT_Alley_Final.png";
import cake from "../assets/imgs/cake.jfif";
import { CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "80%",
    },
  },
  txt: {
    color: "white !important",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20ch",
  },
}));

export default function Staking(props) {
  const { web3, networkId, accounts, addToken } = props;
  const classes = useStyles();
  const [alleyLiquidity, setAlleyLiquidity] = useState(null);
  const [lpToken, setLpToken] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [disableStakeButton, setDisableStakeButton] = useState(false);
  const [disableUnStakeButton, setDisableUnStakeButton] = useState(false);
  const [disableRewardButton, setDisableRewardButton] = useState(false);
  const [isApproved, setApprove] = useState(false);
  const [textFieldValue, settextFieldValue] = useState(false);
  const [alleyBalance, setAlleyBalance] = useState(null);
  const [liqBal, setliqBal] = useState(null);
  const [aliqBal, setaliqBal] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [allownce, setAllownce] = useState(null);
  const [aHash, setAHash] = useState(null);
  const [txHash, setTxHash] = useState(null);
  const [txResult, setTxResult] = useState(null);
  const [txMsg, setTxMsg] = useState(null);
  const [totalLPInPercentage, settotalLPInPercentage] = useState(null);
  const [liquidityInUSD, setliquidityInUSD] = useState(null);
  const [showValidationError, setShowshowValidationError] = useState(false);
  const [errorMSG, setErrorMSG] = useState(false);
  const [alleyEarnedInUSD, setAlleyEarnedInUSD] = useState(null);
  const [locakedLPINUSD, setlocakedLPINUSD] = useState(null);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [alleyPriceUSD, setAlleyPriceUSD] = useState(null);

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };
  const { vertical, horizontal, open } = state;
  const initContracts = () => {
    const Token = new web3.eth.Contract(
      LPToken.abi,
      config.contract_address[0]["LPToken"].address
    );
    const alleyLiq = new web3.eth.Contract(
      AlleyLiquidity.abi,
      config.contract_address[0]["AlleyLiquidity"].address
    );
    setAlleyLiquidity(alleyLiq);
    setLpToken(Token);
  };

  useEffect(() => {
    initContracts();
    var intervalId = setInterval(() => {
      getAllowance();
      getAlleyPrice();
      getLockedTokens();
      getAvailabelLiquidity();
      getRewardsTokens();
      totalSupplyOfLiq();
      getAllowance();
      getAlleyPrice();
      getApy();
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [accounts, networkId]);

  useEffect(() => {
    getLockedTokens();
    getAvailabelLiquidity();
    getRewardsTokens();
    totalSupplyOfLiq();
    getAllowance();
    getAlleyPrice();
    getApy();
  }, [alleyLiquidity]);

  const getAlleyPrice = async () => {
    var res = await axios.get(config.alley_price_url);

    if (res.status === 200) {
      setAlleyPriceUSD("$" + res.data["nft-alley"].usd);
      return res.data["nft-alley"].usd;
    } else {
      return 0;
    }
  };

  const getWBNBPrice = async () => {
    var res = await axios.get(config.wbnb_price_url);
    if (res.status === 200) {
      return res.data["wbnb"].usd;
    } else {
      return 0;
    }
  };

  const getLpTotalSupply = async () => {
    if (lpToken) {
      try {
        var bal = await lpToken.methods.totalSupply().call();
        return web3.utils.fromWei(bal.toString(), "ether");
      } catch (e) {
        console.error(e);
      }
    }
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getReserves = async () => {
    if (lpToken) {
      try {
        var bal = await lpToken.methods.getReserves().call();

        return bal;
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getApy = async () => {
    var resv = await getReserves();
    if (resv) {
      var rewardRate = await getRewardRate();
      var alley_in_usd = await getAlleyPrice();
      var wbnb = await getWBNBPrice();
      var totalLockedToken = await totalSupplyOfLiq();
      var totalSup = await totalSupplyOfLP();
      var lpTotalSupply = await getLpTotalSupply();

      var alley_lp_usd = web3.utils.fromWei(resv[1], "ether") * alley_in_usd;
      var wbnb_lp_usd = web3.utils.fromWei(resv[0], "ether") * wbnb;

      var totlP = alley_lp_usd + wbnb_lp_usd;

    
      var numerator = rewardRate * 86400 * 365 * alley_in_usd * 100;
      var denominator =
        ((alley_lp_usd + wbnb_lp_usd) / lpTotalSupply) * totalLockedToken;

     
      settotalLPInPercentage((numerator / denominator).toFixed(2));
      setliquidityInUSD(
        numberWithCommas((alley_lp_usd + wbnb_lp_usd).toFixed(2))
      );
    }
  };

  const getRewardRate = async () => {
    if (alleyLiquidity) {
      try {
        var rr = await alleyLiquidity.methods.rewardRate().call();
        return web3.utils.fromWei(rr.toString(), "ether");
      } catch (e) {
        console.error(e);
        return 0;
      }
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const approve = async () => {
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);

    // Increment to One and get the new Nonce
    var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;

    setDisableButton(true);

    try {
      await lpToken.methods
        .approve(
          config.contract_address[0]["AlleyLiquidity"].address,

          "1000000000000000000000000"
        )
        .send({
          from: accounts[0],
          nonce: web3.utils.toHex(newNonce),
          gasPrice: web3.utils.toHex(parseInt(config.gas_fee) * 10 ** 9),
          gasLimit: config.gas_limit,
        })
        .on("receipt", (res) => {
          setApprove(true);
          setDisableButton(false);
          setTxResult("success");
          setTxMsg("Transaction was successful");
          setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
          setAHash(res.transactionHash.toString());
          handleClick({ vertical: "bottom", horizontal: "left" })();
          getAllowance();
          getAlleyPrice();
          getLockedTokens();
          getAvailabelLiquidity();
          getRewardsTokens();
          totalSupplyOfLiq();
          getAllowance();
          getAlleyPrice();
          getApy();
        })
        .on("error", (error, res) => {
          setDisableButton(false);
          if (res) {
            setTxResult("error");
            setTxMsg("Error encountered during contract execution");
            setTxHash(res.transactionHash.toString().substring(0, 40) + "...");
            handleClick({ vertical: "bottom", horizontal: "left" })();
            setAHash(res.transactionHash.toString());
          }
        });
    } catch (e) {
      setDisableButton(false);
    }
  };

  const stake = async () => {
    if (parseFloat(textFieldValue) > parseFloat(aliqBal)) {
      //setShowshowValidationError(false);
      //setErrorMSG("You don't have enough Cake-LP to stake")
      alert("You don't have enough Cake-LP to stake");
    } else if (parseFloat(textFieldValue) <= parseFloat(-1)) {
      //setShowshowValidationError(false);
      //setErrorMSG("Invalid Cake-LP unit.")
      alert("Invalid Cake-LP unit.");
    } else {
      //setShowshowValidationError(true);

      var val = parseFloat(textFieldValue) * 10 ** 18;
      var txNonce = await web3.eth.getTransactionCount(accounts[0]);

      // Increment to One and get the new Nonce
      var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;
      setDisableStakeButton(true);

      try {
        await alleyLiquidity.methods
          .stake(web3.utils.toBN(val).toString())
          .send({
            from: accounts[0],
            nonce: web3.utils.toHex(newNonce),
            gasPrice: web3.utils.toHex(parseInt(config.gas_fee) * 10 ** 9),
            gasLimit: config.gas_limit,
          })
          .on("receipt", (res) => {
            getLockedTokens();
            getAvailabelLiquidity();
            getRewardsTokens();
            totalSupplyOfLiq();
            setDisableStakeButton(false);

            setTxResult("success");
            setTxMsg("Transaction was successful");
            setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
            setAHash(res.transactionHash.toString());
            handleClick({ vertical: "bottom", horizontal: "left" })();
            settextFieldValue("");
            getAlleyPrice();
            getApy();
          })
          .on("error", (error, res) => {
            setDisableStakeButton(false);

            if (res) {
              setTxResult("error");
              setTxMsg("Error encountered during contract execution");
              setTxHash(
                res.transactionHash.toString().substring(0, 40) + "..."
              );
              handleClick({ vertical: "bottom", horizontal: "left" })();
              setAHash(res.transactionHash.toString());
            }
          });
      } catch (e) {
        setDisableStakeButton(false);
      }
    }
  };

  const unstake = async () => {
    var val = parseFloat(textFieldValue) * 10 ** 18;
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);

    // Increment to One and get the new Nonce
    var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;
    setDisableUnStakeButton(true);

    try {
      await alleyLiquidity.methods
        .unstake(web3.utils.toBN(val).toString())
        .send({
          from: accounts[0],
          nonce: web3.utils.toHex(newNonce),
          gasPrice: web3.utils.toHex(parseInt(config.gas_fee) * 10 ** 9),
          gasLimit: config.gas_limit,
        })
        .on("receipt", (res) => {
          settextFieldValue("");
          getLockedTokens();
          getAvailabelLiquidity();
          getRewardsTokens();
          totalSupplyOfLiq();
          setTxResult("success");
          setTxMsg("Transaction was successful");
          setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
          setAHash(res.transactionHash.toString());
          handleClick({ vertical: "bottom", horizontal: "left" })();
          setDisableUnStakeButton(false);
          getAlleyPrice();
          getApy();
        })
        .on("error", (error, res) => {
          setDisableUnStakeButton(false);

          if (res) {
            setTxResult("error");
            setTxMsg("Error encountered during contract execution");
            setTxHash(res.transactionHash.toString().substring(0, 40) + "...");
            handleClick({ vertical: "bottom", horizontal: "left" })();
            setAHash(res.transactionHash.toString());
          }
        });
    } catch (e) {
      setDisableUnStakeButton(false);
    }
  };

  const getreward = async () => {
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);

    // Increment to One and get the new Nonce
    var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;
    setDisableRewardButton(true);

    try {
      await alleyLiquidity.methods
        .getReward()
        .send({
          from: accounts[0],
          nonce: web3.utils.toHex(newNonce),
          gasPrice: web3.utils.toHex(parseInt(config.gas_fee) * 10 ** 9),
          gasLimit: config.gas_limit,
        })
        .on("receipt", (res) => {
          setDisableRewardButton(false);
          getRewardsTokens();
          getApy();
          setTxResult("success");
          setTxMsg("Transaction was successful");
          setTxHash(res.transactionHash.toString().substring(0, 30) + "...");
          setAHash(res.transactionHash.toString());
          handleClick({ vertical: "bottom", horizontal: "left" })();
          getAlleyPrice();
        })
        .on("error", (error, res) => {
          setDisableUnStakeButton(false);
          if (res) {
            setTxResult("error");
            setTxMsg("Error encountered during contract execution");
            setTxHash(res.transactionHash.toString().substring(0, 40) + "...");
            handleClick({ vertical: "bottom", horizontal: "left" })();
            setAHash(res.transactionHash.toString());
          }
        });
    } catch (e) {
      setDisableRewardButton(false);
    }
  };

  const _handleTextFieldChange = (e) => {
    settextFieldValue(e.target.value);
  };

  const addUniV2Lid = () => {
    window.open(config.pool_url, "_blank");
  };

  const stakeUI = () => {
    return (
      <div>
        <TextField
          label="LP-Tokens"
          id="outlined-margin-normal"
          type="number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          onChange={_handleTextFieldChange}
          value={textFieldValue}
        />
      </div>
    );
  };

  const getLockedTokens = async () => {
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);
    var lqbal = 0;

    // Increment to One and get the new Nonce
    var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;

    if (alleyLiquidity) {
      try {
        var bal = await alleyLiquidity.methods
          .balanceOf(accounts[0])
          .call();

        lqbal = web3.utils.fromWei(bal.toString(), "ether");
        var val = Number(lqbal);
        var res = lqbal.toString().split(".");
        if (res.length == 1) {
          // Set the number to two decimal places
          val = val.toFixed(2);
        }
        
        if(!(val.toString().split(".").length > 1)){
          val = val.toString()+"."+"000000"
        }

        setliqBal(val)
       
      } catch (e) {
        console.error(e);
      }
    }
    return lqbal;
  };

  const getAllowance = async () => {
    if (lpToken) {
      try {
        var bal = await lpToken.methods
          .allowance(
            accounts[0],
            config.contract_address[0]["AlleyLiquidity"].address
          )
          .call();
        setAllownce(web3.utils.fromWei(bal.toString(), "ether"));
      } catch (e) {}
    }
  };

  const totalSupplyOfLiq = async () => {
    var tot = 0;
    if (alleyLiquidity) {
      try {
        var bal = await alleyLiquidity.methods.totalSupply().call();
        var setBal = web3.utils.fromWei(bal.toString(), "ether");
        setTotalSupply(parseFloat(setBal).toFixed(2));
        tot = parseFloat(setBal).toFixed(2);
      } catch (e) {
        console.error(e);
      }
    }
    return setBal;
  };

  const totalSupplyOfLP = async () => {
    var tot = 0;
    if (lpToken) {
      try {
        var bal = await lpToken.methods.totalSupply().call();

        tot = web3.utils.fromWei(bal.toString(), "ether");
      } catch (e) {
        console.error(e);
      }
    }
    return tot;
  };

  const getRewardsTokens = async () => {
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);

    // Increment to One and get the new Nonce
    var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;

    if (alleyLiquidity) {
      try {
        var bal = await alleyLiquidity.methods
          .earned(accounts[0])
          .call();
        var roundOffBal = web3.utils.fromWei(bal.toString(), "ether");
        var val = Number(roundOffBal);
        var res = roundOffBal.toString().split(".");

        if (res.length == 1) {
          // Set the number to two decimal places
          val = val.toFixed(2);
        }

        if(!(val.toString().split(".").length > 1)){
          val = val.toString()+"."+"000000"
        }

        setAlleyBalance(val);
      } catch (e) {}
    }
  };
  const getAvailabelLiquidity = async () => {
    var txNonce = await web3.eth.getTransactionCount(accounts[0]);

    if (alleyLiquidity) {
      var newNonce = txNonce === null ? 1 : parseInt(txNonce) + 1;

      try {
        var bal = await lpToken.methods
          .balanceOf(accounts[0])
          .call();
        var roundOffBal = web3.utils.fromWei(bal.toString(), "ether");
        var val = Number(roundOffBal);
        var res = roundOffBal.toString().split(".");

        if (res.length == 1) {
          // Set the number to two decimal places
          val = val.toFixed(2);
        }
        if(!(val.toString().split(".").length > 1)){
          val = val.toString()+"."+"000000"
        }

        setaliqBal(val);
      } catch (e) {}
    }
    // Increment to One and get the new Nonce
  };

  const alertSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={txResult}>
          {txMsg} <br />{" "}
          <a
            target="_blank"
            href={config.chain_url + "tx/" + aHash}
            style={{ color: "#fff" }}
          >
            {txHash}
          </a>
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      {alertSnackbar()}
      <div>
        <div className="form-wrapper">
          <div>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "700",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              ALLEY - BNB LP Staking
            </p>
            <p
              style={{
                fontSize: "14px",
                color: "gray",
                marginTop: "0px",
                marginBottom: "5px",
              }}
            >
              Lock your ALLEY-BNB Cake-LP tokens to earn extra ALLEY!
            </p>
          </div>
          <div className="add-alley-div ">
            <div className="text-wrapper">
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  marginBottom: "5px",
                  fontWeight:"600"
                }}
              >
                 $ALLEY staking program has now ended.
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "gray",
                  marginTop: "0px",
                  marginBottom: "5px",
                  wordBreak: "break-word"
                }}
              >
                Thank you for your support. $ALLEY staking program has now ended. Stay tuned to our announcement channels for  further updates
              </p>
            </div>
            <div className="btn-wrapper" style={{ float: "right" }}>
             
            </div>
          </div>
          {/* <div className="add-alley-div ">
            <div className="text-wrapper">
              <p
                style={{
                  fontSize: "18px",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Create ALLEY-BNB Cake-LP tokens
              </p>
              <p
                style={{
                  fontSize: "14px",
                  color: "gray",
                  marginTop: "0px",
                  marginBottom: "5px",
                }}
              >
                Provide liquidity in PancakeSwap to get LP tokens.
              </p>
            </div>
            <div className="btn-wrapper" style={{ float: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "auto", marginTop: "5px" }}
                onClick={addUniV2Lid}
                disabled={true}
              >
                Add Alley-BNB Liquidity
              </Button>
            </div>
          </div> */}
          <div className="calc-div">
            <div className="div-wrapper">
              <div>
                <p style={{ fontWeight: "600" }}>Reward Program</p>
              </div>
              <div className="reward-row">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  Total Value Locked
                </p>
                <p
                  className="right-p"
                  style={{
                    fontSize: "16px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                >
                  {totalSupply ? totalSupply : "0.000 "} LP
                </p>
              </div>
              <div className="reward-row">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  Annual Percentage Yield
                </p>
                <p
                  className="right-p"
                  style={{
                    fontSize: "16px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                >
                  -
                  {/* {totalLPInPercentage &&
                  totalLPInPercentage != "NaN" &&
                  totalLPInPercentage != "undefined" &&
                  totalLPInPercentage != "Infinity"
                    ? totalLPInPercentage + "%"
                    : "0.00%"} */}
                </p>
              </div>
            </div>
            <div className="left-div-wrapper ">
              <div>
                <p style={{ fontWeight: "600" }}>Pool Information</p>
              </div>
              <div className="reward-row">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  Total Liquidity
                </p>
                <p
                  className="right-p"
                  style={{
                    fontSize: "16px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                >
                  {liquidityInUSD ? "$" + liquidityInUSD : "$0.00"}
                </p>
              </div>
              <div className="reward-row">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                >
                  ALLEY Token Price
                </p>
                <p
                  className="right-p"
                  style={{
                    fontSize: "16px",
                    marginTop: "0px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                >
                  {alleyPriceUSD ? alleyPriceUSD : "$0.00"}
                </p>
              </div>
            </div>
          </div>
          <div className="approve-claim">
            <div className="approve-div">
              <div className="logo-div">
                <img src={cake} className="logo-img" />
              </div>
              <div className="text-div">
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                  title={liqBal}
                >
                  {liqBal && liqBal > 0
                    ? +liqBal.toString().split(".")[0] +
                      "." +
                      liqBal.toString().split(".")[1].substr(0, 6)
                    : "0.000000"}{" "}
                  LP Locked
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "black",
                    marginTop: "0px",
                    marginBottom: "5px",
                  }}
                  title={aliqBal}
                >
                  Available:{" "}
                  {/* {aliqBal} */}
                  {aliqBal && aliqBal > 0
                    ? aliqBal.toString().split(".")[0] +
                      "." +
                      aliqBal.toString().split(".")[1].substr(0, 6)
                    : "0.000000"}{" "}
                  LP
                </p>
              </div>
              <hr style={{ color: "gray" }} />
              <div
                className="btn-div"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
               
                  {stakeUI()}
                

              
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      onClick={stake}
                      size="large"
                      color="secondary"
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                      disabled={true}
                    >
                      {" "}
                      {!disableStakeButton ? (
                        "Stake"
                      ) : (
                        <CircularProgress size={30.0} />
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={unstake}
                      size="large"
                      style={{ marginLeft: "5px", marginTop: "5px" }}
                      disabled={disableStakeButton}
                    >
                      {" "}
                      {!disableUnStakeButton ? (
                        "UnStake"
                      ) : (
                        <CircularProgress size={30.0} />
                      )}
                    </Button>
                  </div>
              
              </div>
            </div>
            <div className="approve-div">
              <div className="logo-div">
                <img src={nftalley} className="logo-img" />
              </div>
              <div className="text-div">
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    fontWeight: "bolder",
                  }}
                  title={alleyBalance}
                >
                  {alleyBalance && alleyBalance > 0
                    ? alleyBalance.toString().split(".")[0] +
                      "." +
                      alleyBalance.toString().split(".")[1].substr(0, 6)
                    : "0.000000"}{" "}
                  ALLEY Earned
                </p>
              </div>
              <hr />
              <div className="btn-div">
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={disableRewardButton}
                  onClick={getreward}
                  style={{ marginTop: "5px" }}
                >
                  {!disableRewardButton ? (
                    "Claim Reward"
                  ) : (
                    <CircularProgress size={30.0} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
