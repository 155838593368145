import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

const getWeb3 = (provider_nos) =>
  new Promise(async (resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    var provider;

    //  Enable session (triggers QR Code modal)
    if (provider_nos === 1) {
      try {
        provider = new WalletConnectProvider({
          rpc: {
            56: "https://bsc-dataseed1.ninicoin.io",
            97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            3:"https://ropsten.infura.io/v3/e60d4d2247f544cc918747dc63b2ffd9"
          },
          infuraId:"e60d4d2247f544cc918747dc63b2ffd9",
          networkId: 56,
          chainId: 56,
        });
        
        await provider.enable();
       
      } catch (e) {
      
      }
    } else if (provider_nos === 0) {
      provider = window.ethereum;
    }

    // Modern dapp browsers...
    if (provider) {
      const web3j = new Web3(provider);
      try {
        // Request account access if needed
        if (provider_nos === 0) {
          await window.ethereum.enable();
        }

        // Acccounts now exposed
        resolve(web3j);
      } catch (error) {
        reject(error);
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3j = window.web3;
      
      resolve(web3j);
    }
    // Fallback to localhost; use dev console port by default...
    else {
      const provider = new Web3.providers.HttpProvider("http://127.0.0.1:8545");
      const web3j = new Web3(provider);
      
      resolve(web3j);
    }
  });

export default getWeb3;
