import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import ConnectWalletRoot from "./LandingPage/ConnectWalletRoot";
import TokenRelease from "../src/LandingPage/TokenRelease";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <ConnectWalletRoot />
        </Route>
        <Route path="/alley/investor-check">
          <App />
        </Route>
        <Route path="/alley/investor">
          <ConnectWalletRoot />
        </Route>
        <Route path="/alley/inhouse/investor">
          <TokenRelease />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
