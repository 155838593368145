import "./main.css";

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import TokenAllocation from "../Data/TokenAllocation.txt";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import clip from "../assets/imgs/clip.svg"
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "80%",
      
    },
  },
  txt: {
    color: "white !important",
  },
}));

export default function CheckTokenAllocation() {
  const classes = useStyles();

  const [investorKey, handlKey] = useState("");
  const [found, setFound] = useState(false);
  const [doneSearching, setDoneSearching] = useState(false);

  
  const handleSetKey = (event) => {
    handlKey(event.target.value);
  };
  const CheckAllocation = (e) => {
    e.preventDefault();
    var found = false;
    var list1 =[]
     fetch(TokenAllocation)
      .then((r) => r.text() )
       .then((text) => {
        list1 = text.split(',')
        setFound(list1.includes(investorKey));
        setDoneSearching(true)
        (list1)
        (found)
        (list1.includes(investorKey))
       }
    );
  };
  return (
      <div className="form-wrapper">
        <h1 style={{color:"#000"}}>Investor Allocation Check</h1>
        <img src={clip} alt="..." height="150" />
        <p style={{color:"#000"}}>Please enter you wallet address to check for white listing</p>
          <div style={{textAlign:"center",}}>
    {doneSearching? 
      (found?
          <div style={{ display:"flex",  justifyContent:"center", alignItems:"center", flexDirection:"column" }}>
              <CheckCircleIcon color="primary" fontSize="large"/> 
              <p  style={{ color:"#000000cc"}} >Congratulations, You are white listed</p>
          </div>
      :
          
          <div style={{ display:"flex",  justifyContent:"center", alignItems:"center", flexDirection:"column" }}>
              <CancelIcon color="error" fontSize="large"/> 
              <p  style={{ color:"#000000cc"}} >Oops, You are not white listed</p>
          </div>
          )
      
      :""
    }
      
</div>
    <form className={classes.root}  onSubmit={CheckAllocation} autoComplete="off">
      
      
      <TextField
        id="filled-basic"
        className={classes.txt}
        label="Enter wallet address"
        variant="outlined"
        onChange={handleSetKey}
        required
      />
      <Button
        className="btn"
        variant="contained"
        color="primary"
        type="submit"
      >
        Check token allocation
      </Button>
      <br />

    </form>
      </div>
    
  );
}
